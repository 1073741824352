/* POKEBALL ANIMATIONS */
/* shake to rotate g.gravity */
svg.fetching g.gravity {
    /* ! infinite given the unknown time it takes for the request to be resolved/rejected */
    animation: shake 0.75s infinite cubic-bezier(0.645, 0.045, 0.355, 1);
}
@keyframes shake {
    20% {
        transform: rotate(-10deg);
    }
    60% {
        transform: rotate(10deg);
    }
    80% {
        transform: rotate(0deg);
    }
}

/* pulse to change the color of the innermost circle */
svg.fetching g.inner {
    /* ! infinite given the unknown time it takes for the request to be resolved/rejected */
    animation: pulse 0.75s infinite cubic-bezier(0.645, 0.045, 0.355, 1);
}
@keyframes pulse {
    25% {
        opacity: 0.7;
    }
    50% {
        opacity: 0;
    }
}

/* squash for the pokeball and the success animation */
svg.success g.ball {
    animation: squash 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 2 alternate;
}
@keyframes squash {
    to {
        transform: scaleY(0.9);
    }
}

/* scaleUp for the stars and the success animation */
svg.success g.stars {
    animation: scaleUp 0.2s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}
@keyframes scaleUp {
    to {
        transform: scale(1);
    }
}

/* scaleUp for the arcs in between the ball's halves and for the failure animation */
svg.failure g.open {
    animation: scaleUp 0.2s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}

/* translateUp for the top half and the failure animation */
svg.failure g.top {
    animation: translateUp 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}
@keyframes translateUp {
    to {
        transform: translateY(-10px);
    }
}

/* translateDown for the bottom half and the failure animation */
svg.failure g.bottom {
    animation: translateDown 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}
@keyframes translateDown {
    to {
        transform: translateY(5px);
    }
}

/* translateHigher for the center and the failure animation */
svg.failure g.center {
    animation: translateHigher 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}
@keyframes translateHigher {
    to {
        transform: translateY(-18px);
    }
}
